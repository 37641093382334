const HowItWorks = () => {
  return (
    <div
      id="how"
      className="items-center max-w-[1330px] m-auto pt-32 box-border p-10"
    >
      <p className="text-[20px] sm:text-[30px] text-black-blue font-['Montserrat'] font-bold">
        How It Works
      </p>

      <div className="flex flex-col md:flex-row justify-center gap-3 sm:gap-7 mt-10 mb-[40px] text-center  text-black-blue">
        <p className="text-[16px] sm:text-[24px] font-['Montserrat'] font-bold">
          Aggregate data from <br />a variety of sources.
        </p>

        <p className="text-[16px] sm:text-[24px] font-['Montserrat'] font-bold">
          +
        </p>

        <p className="text-[16px] sm:text-[24px] font-['Montserrat'] font-bold">
          Analyze the data and <br />
          recommend actions.
        </p>
      </div>

      <div className="max-w-[1100px] m-auto">
        <img src={"../images/laptopkriya.png"} alt="laptopkriya" />
      </div>
    </div>
  );
};

export default HowItWorks;
