import { useRef } from "react";
import ContactUs from "./sections/ContactUs";
import Core from "./sections/Core";
import HeroBanner from "./sections/HeroBanner";
import HowItWorks from "./sections/HowItWorks";
import NavBar from "./sections/NavBar";
import WebFooter from "./sections/Webfooter";

const App = () => {
  const navBarRef = useRef(null);

  const handlebNav = () => {
    if(navBarRef.current.classList.contains("h-screen")) {
      navBarRef.current.classList.add("h-0");
      navBarRef.current.classList.remove("h-screen")
    } else {
      navBarRef.current.classList.remove("h-0");
      navBarRef.current.classList.add("h-screen")
    }
  }

  return (
    <>
      <div
        ref={navBarRef}
        className="transition-all ease-in-out duration-500 h-0 w-full overflow-hidden bg-black-opacity flex flex-col items-center sm:hidden justify-center gap-5 fixed z-20"
      >
        <button onClick={handlebNav} className="absolute top-7 right-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="fill-white w-10"
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </button>

        <a href="#about" onClick={handlebNav} className="text-[24px] font-medium text-white opacity-100 select-none">About</a>

        <a href="#contact" onClick={handlebNav} className="text-[24px] font-medium text-white opacity-100">
          Contact us
        </a>

        <p className="text-[24px] font-medium text-white opacity-100">
          Sign up
        </p>

        <p onClick={() => window.location.href = "https://app.kriyahr.com"}
        className="text-[24px] font-medium text-white opacity-100">Log in</p>
      </div>

      <NavBar navBarRef={navBarRef} />

      <div>
        <HeroBanner />
        <Core />
        <HowItWorks />
        <ContactUs />
        <WebFooter />
      </div>
    </>
  );
};

export default App;
