const NavBar = ({ navBarRef }) => {
  const handlebNav = () => {
    if (navBarRef.current.classList.contains("h-screen")) {
      navBarRef.current.classList.add("h-0");
      navBarRef.current.classList.remove("h-screen");
    } else {
      navBarRef.current.classList.remove("h-0");
      navBarRef.current.classList.add("h-screen");
    }
  };

  return (
    <div className="w-full fixed backdrop-blur-sm pt-5 px-5 z-10">
      <div className="box-border bg-white py-3 px-10 shadow-lg rounded-full flex flex-row justify-between items-center max-w-[1300px] m-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          zoomAndPan="magnify"
          viewBox="0 0 1195.5 570"
          height="760"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
          className="h-10 sm:h-16"
        >
          <defs>
            <filter x="0%" y="0%" width="100%" height="100%" id="2fa92fef17">
              <feColorMatrix
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                color-interpolation-filters="sRGB"
              />
            </filter>
            <g />
            <clipPath id="81c96e481d">
              <path
                d="M 0 0.121094 L 1195 0.121094 L 1195 569.878906 L 0 569.878906 Z M 0 0.121094 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="54253da24b">
              <path
                d="M 0 216.351562 L 268.523438 216.351562 L 268.523438 484.875 L 0 484.875 Z M 0 216.351562 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="af63edf709">
              <path
                d="M 134.261719 216.351562 C 60.109375 216.351562 0 276.460938 0 350.613281 C 0 424.765625 60.109375 484.875 134.261719 484.875 C 208.414062 484.875 268.523438 424.765625 268.523438 350.613281 C 268.523438 276.460938 208.414062 216.351562 134.261719 216.351562 Z M 134.261719 216.351562 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="86b40ba3e9">
              <path
                d="M 0 89.546875 L 268.523438 89.546875 L 268.523438 358.070312 L 0 358.070312 Z M 0 89.546875 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="14293aa449">
              <path
                d="M 134.261719 89.546875 C 60.109375 89.546875 0 149.660156 0 223.808594 C 0 297.960938 60.109375 358.070312 134.261719 358.070312 C 208.414062 358.070312 268.523438 297.960938 268.523438 223.808594 C 268.523438 149.660156 208.414062 89.546875 134.261719 89.546875 Z M 134.261719 89.546875 "
                clip-rule="nonzero"
              />
            </clipPath>
            <mask id="24823218ed">
              <g filter="url(#2fa92fef17)">
                <rect
                  x="-119.55"
                  width="1434.6"
                  fill="#000000"
                  y="-57"
                  height="684"
                  fill-opacity="0.5"
                />
              </g>
            </mask>
            <clipPath id="cf1bfe3428">
              <path
                d="M 0 0.351562 L 268.523438 0.351562 L 268.523438 268.875 L 0 268.875 Z M 0 0.351562 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="6cf4129d32">
              <path
                d="M 134.261719 0.351562 C 60.109375 0.351562 0 60.460938 0 134.613281 C 0 208.765625 60.109375 268.875 134.261719 268.875 C 208.414062 268.875 268.523438 208.765625 268.523438 134.613281 C 268.523438 60.460938 208.414062 0.351562 134.261719 0.351562 Z M 134.261719 0.351562 "
                clip-rule="nonzero"
              />
            </clipPath>
            <clipPath id="f07358e583">
              <rect x="0" width="269" y="0" height="269" />
            </clipPath>
          </defs>
          <g clip-path="url(#81c96e481d)">
            <path
              fill="#ffffff"
              d="M 0 0.121094 L 1195 0.121094 L 1195 569.878906 L 0 569.878906 Z M 0 0.121094 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
          <g clip-path="url(#54253da24b)">
            <g clip-path="url(#af63edf709)">
              <path
                fill="#008080"
                d="M 0 216.351562 L 268.523438 216.351562 L 268.523438 484.875 L 0 484.875 Z M 0 216.351562 "
                fill-opacity="1"
                fill-rule="nonzero"
              />
            </g>
          </g>
          <g fill="#36454f" fill-opacity="1">
            <g transform="translate(265.808233, 398.114494)">
              <g>
                <path d="M 97.171875 -67.671875 L 73.125 -43.9375 L 73.125 0 L 23.09375 0 L 23.09375 -237.96875 L 73.125 -237.96875 L 73.125 -103.265625 L 146.234375 -172.53125 L 205.890625 -172.53125 L 134.046875 -99.421875 L 212.3125 0 L 151.6875 0 Z M 97.171875 -67.671875 " />
              </g>
            </g>
          </g>
          <g fill="#36454f" fill-opacity="1">
            <g transform="translate(477.445241, 398.114494)">
              <g>
                <path d="M 70.875 -149.765625 C 76.863281 -158.109375 84.9375 -164.414062 95.09375 -168.6875 C 105.25 -172.96875 116.953125 -175.109375 130.203125 -175.109375 L 130.203125 -128.921875 C 124.640625 -129.347656 120.898438 -129.5625 118.984375 -129.5625 C 104.660156 -129.5625 93.4375 -125.550781 85.3125 -117.53125 C 77.1875 -109.519531 73.125 -97.492188 73.125 -81.453125 L 73.125 0 L 23.09375 0 L 23.09375 -172.53125 L 70.875 -172.53125 Z M 70.875 -149.765625 " />
              </g>
            </g>
          </g>
          <g fill="#36454f" fill-opacity="1">
            <g transform="translate(615.650617, 398.114494)">
              <g>
                <path d="M 23.09375 -172.53125 L 73.125 -172.53125 L 73.125 0 L 23.09375 0 Z M 48.109375 -196.59375 C 38.910156 -196.59375 31.425781 -199.265625 25.65625 -204.609375 C 19.882812 -209.953125 17 -216.578125 17 -224.484375 C 17 -232.398438 19.882812 -239.03125 25.65625 -244.375 C 31.425781 -249.71875 38.910156 -252.390625 48.109375 -252.390625 C 57.296875 -252.390625 64.773438 -249.820312 70.546875 -244.6875 C 76.316406 -239.5625 79.203125 -233.148438 79.203125 -225.453125 C 79.203125 -217.117188 76.316406 -210.222656 70.546875 -204.765625 C 64.773438 -199.316406 57.296875 -196.59375 48.109375 -196.59375 Z M 48.109375 -196.59375 " />
              </g>
            </g>
          </g>
          <g fill="#36454f" fill-opacity="1">
            <g transform="translate(712.169944, 398.114494)">
              <g>
                <path d="M 194.34375 -172.53125 L 116.421875 10.578125 C 108.503906 30.460938 98.71875 44.46875 87.0625 52.59375 C 75.414062 60.71875 61.359375 64.78125 44.890625 64.78125 C 35.910156 64.78125 27.035156 63.390625 18.265625 60.609375 C 9.503906 57.828125 2.347656 53.976562 -3.203125 49.0625 L 15.078125 13.46875 C 18.921875 16.882812 23.351562 19.554688 28.375 21.484375 C 33.40625 23.410156 38.378906 24.375 43.296875 24.375 C 50.140625 24.375 55.695312 22.71875 59.96875 19.40625 C 64.25 16.09375 68.097656 10.585938 71.515625 2.890625 L 72.15625 1.28125 L -2.5625 -172.53125 L 49.0625 -172.53125 L 97.5 -55.484375 L 146.234375 -172.53125 Z M 194.34375 -172.53125 " />
              </g>
            </g>
          </g>
          <g fill="#36454f" fill-opacity="1">
            <g transform="translate(903.925912, 398.114494)">
              <g>
                <path d="M 92.6875 -175.109375 C 119.40625 -175.109375 139.925781 -168.742188 154.25 -156.015625 C 168.582031 -143.296875 175.75 -124.109375 175.75 -98.453125 L 175.75 0 L 128.921875 0 L 128.921875 -21.484375 C 119.515625 -5.453125 101.984375 2.5625 76.328125 2.5625 C 63.066406 2.5625 51.570312 0.316406 41.84375 -4.171875 C 32.125 -8.660156 24.695312 -14.859375 19.5625 -22.765625 C 14.425781 -30.679688 11.859375 -39.660156 11.859375 -49.703125 C 11.859375 -65.742188 17.898438 -78.359375 29.984375 -87.546875 C 42.066406 -96.742188 60.722656 -101.34375 85.953125 -101.34375 L 125.71875 -101.34375 C 125.71875 -112.25 122.398438 -120.640625 115.765625 -126.515625 C 109.140625 -132.398438 99.203125 -135.34375 85.953125 -135.34375 C 76.753906 -135.34375 67.71875 -133.894531 58.84375 -131 C 49.976562 -128.113281 42.441406 -124.210938 36.234375 -119.296875 L 18.28125 -154.265625 C 27.6875 -160.890625 38.960938 -166.019531 52.109375 -169.65625 C 65.265625 -173.289062 78.789062 -175.109375 92.6875 -175.109375 Z M 88.828125 -31.109375 C 97.378906 -31.109375 104.96875 -33.082031 111.59375 -37.03125 C 118.226562 -40.988281 122.9375 -46.816406 125.71875 -54.515625 L 125.71875 -72.15625 L 91.40625 -72.15625 C 70.875 -72.15625 60.609375 -65.421875 60.609375 -51.953125 C 60.609375 -45.535156 63.117188 -40.457031 68.140625 -36.71875 C 73.171875 -32.976562 80.066406 -31.109375 88.828125 -31.109375 Z M 88.828125 -31.109375 " />
              </g>
            </g>
          </g>
          <g clip-path="url(#86b40ba3e9)">
            <g clip-path="url(#14293aa449)">
              <path
                fill="#cc5500"
                d="M 0 89.546875 L 268.523438 89.546875 L 268.523438 358.070312 L 0 358.070312 Z M 0 89.546875 "
                fill-opacity="1"
                fill-rule="nonzero"
              />
            </g>
          </g>
          <g mask="url(#24823218ed)">
            <g transform="matrix(1, 0, 0, 1, 0, 216)">
              <g clip-path="url(#f07358e583)">
                <g clip-path="url(#cf1bfe3428)">
                  <g clip-path="url(#6cf4129d32)">
                    <path
                      fill="#008080"
                      d="M 0 0.351562 L 268.523438 0.351562 L 268.523438 268.875 L 0 268.875 Z M 0 0.351562 "
                      fill-opacity="1"
                      fill-rule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <div className="hidden sm:flex flex-row justify-center items-center gap-5">
          <a
            href={"#about"}
            className="text-black cursor-pointer text-[14px] font-['Poppins'] select-none"
          >
            About
          </a>

          <a
            href={"#contact"}
            className="text-black cursor-pointer text-[14px] font-['Poppins'] select-none"
          >
            Contact Us
          </a>

          <div className="flex flex-row gap-2">
            {/* <button
              onClick={() => (window.location.href = "#contact")}
              className="bg-black-blue text-white text-[14px] px-3 py-2 rounded-[8px] font-['Poppins']"
            >
              Sign up
            </button> */}

            <button
              onClick={() => (window.location.href = "https://app.kriyahr.com")}
              className="bg-black-blue text-white text-[14px] px-3 py-2 rounded-[8px] font-['Poppins']"
            >
              Log in
            </button>
          </div>
        </div>

        <button className="block sm:hidden" onClick={handlebNav}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-black-blue"
          >
            <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
