const Core = () => {
  return (
    <div
      className="max-w-[1300px] m-auto pt-32 box-border p-10 min-h-screen"
      id="about"
    >
      <p className="text-[20px] sm:text-[30px] text-black-blue font-['Montserrat'] font-bold">
        Let kriya guide you in creating a more motivated, <br /> committed, and
        high-performing team.
      </p>

      <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 gap-5 max-w-[1000px] m-auto">
        <div className="bg-burnt-orange p-8 rounded-[8px] h-[335px] shadow-md relative">
          <p className="text-white text-[16px] sm:text-[18px] font-['Montserrat']">
            <span className="font-bold">Unlock</span> the full potential of your
            workforce through data-driven insights by seamlessly integrating
            with various HR systems.
          </p>

          <svg
            viewBox="0 0 83 103"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute fill-white h-20 bottom-5 right-5"
          >
            <path
              d="M66.8089 31.158V26.087C66.8089 12.1044 55.4326 0.728027 41.45 0.728027C27.4674 0.728027 16.092 12.1044 16.092 26.087V41.302H11.02C5.42625 41.302 0.876953 45.8512 0.876953 51.445V92.018C0.876953 97.6127 5.42625 102.162 11.02 102.162H71.88C77.4746 102.162 82.0239 97.6127 82.0239 92.018V51.445C82.0239 45.8512 77.4746 41.302 71.88 41.302H26.235V26.087C26.235 17.6981 33.0621 10.872 41.45 10.872C49.8388 10.872 56.665 17.6981 56.665 26.087V31.158H66.8089Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="bg-forest-green p-8 rounded-[8px] h-[335px] shadow-md relative">
          <p className="text-white text-[16px] sm:text-[18px] font-['Montserrat']">
            <span className="font-bold">Gain</span> comprehensive view of
            employee engagement and workplace dynamics, turning complex data
            into actionable intelligience.
          </p>

          <svg
            viewBox="0 0 165 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute fill-white h-20 bottom-5 right-5"
          >
            <path
              d="M147.803 40.9975C145.839 41.0118 143.894 41.3796 142.061 42.084L122.477 26.0519C122.959 24.3048 123.302 22.4915 123.302 20.5807C123.302 9.26075 114.204 0.163086 102.884 0.163086C91.5653 0.163086 82.4676 9.26075 82.4676 20.5807C82.4676 23.8143 83.2847 26.8278 84.6075 29.5397L60.8498 57.6167C59.8993 57.4386 58.9344 57.3437 57.967 57.3312C56.5054 57.3312 55.1244 57.5764 53.7855 57.9353L32.8541 37.004C33.2211 35.6722 33.4663 34.2921 33.4663 32.8306C33.4663 23.7812 26.1819 16.4968 17.1326 16.4968C8.08323 16.4968 0.798828 23.7812 0.798828 32.8306C0.798828 41.8799 8.08323 49.1643 17.1326 49.1643C18.5941 49.1643 19.9751 48.9191 21.314 48.5602L42.2454 69.4916C41.8534 70.8484 41.6475 72.2527 41.6332 73.665C41.6332 82.7143 48.9176 89.9987 57.967 89.9987C67.0163 89.9987 74.3007 82.7143 74.3007 73.665C74.3007 71.7291 73.9087 69.9087 73.2965 68.1849L97.0865 40.0747C98.94 40.6135 100.86 40.9975 102.884 40.9975C106.108 40.9796 109.28 40.1929 112.138 38.7027L131.73 54.7339C131.576 55.5922 131.489 56.4604 131.469 57.3312C131.469 66.3806 138.753 73.665 147.803 73.665C156.852 73.665 164.136 66.3806 164.136 57.3312C164.136 48.2819 156.852 40.9975 147.803 40.9975Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="bg-teal p-8 rounded-[8px] h-[335px] shadow-md relative">
          <p className="text-white text-[16px] sm:text-[18px] font-['Montserrat']">
            <span className="font-bold">Understand</span> and improve work
            culture, fostering an environment where productivity and employee
            satisfaction thrive together.
          </p>

          <svg
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute fill-white h-20 bottom-5 right-5"
          >
            <path
              d="M51.4772 0.488281C23.3026 0.488281 0.390625 23.4065 0.390625 51.5776C0.390625 79.7486 23.3026 102.667 51.4772 102.667C79.6518 102.667 102.564 79.7486 102.564 51.5776C102.564 23.4065 79.6518 0.488281 51.4772 0.488281ZM69.3234 31.142C70.3437 31.1161 71.3552 31.2942 72.3039 31.6656C73.2526 32.0379 74.1207 32.5955 74.8456 33.307C75.5795 34.0186 76.1524 34.8688 76.5551 35.8077C76.9489 36.7465 77.1547 37.7552 77.1547 38.7746C77.1547 39.7931 76.9489 40.8018 76.5462 41.7406C76.1524 42.6795 75.5706 43.5297 74.8456 44.2403C74.1117 44.951 73.2436 45.5086 72.2949 45.88C71.3462 46.2514 70.3349 46.4286 69.3146 46.4018C67.3277 46.3499 65.4392 45.5229 64.052 44.0971C62.6647 42.6714 61.886 40.7597 61.886 38.7692C61.886 36.7796 62.6647 34.8679 64.052 33.4431C65.4392 32.0182 67.3365 31.1921 69.3234 31.142ZM33.5951 31.142C34.6065 31.142 35.5999 31.3407 36.5307 31.7265C37.4615 32.1122 38.3027 32.677 39.0187 33.3885C39.7258 34.1009 40.2897 34.9458 40.6746 35.8766C41.0594 36.8065 41.2562 37.8035 41.2562 38.8104C41.2562 39.8172 41.0594 40.8134 40.6746 41.7433C40.2897 42.6732 39.7258 43.519 39.0098 44.2305C38.3027 44.942 37.4526 45.5059 36.5218 45.8907C35.591 46.2765 34.5975 46.4743 33.5951 46.4734C31.5545 46.4734 29.6123 45.6643 28.1714 44.2269C26.7304 42.7886 25.9249 40.8385 25.9249 38.805C25.9249 36.7716 26.7394 34.8223 28.1714 33.3849C29.6123 31.9484 31.5635 31.1411 33.5951 31.142ZM51.4772 82.2313C25.9339 82.2313 20.8234 56.6862 20.8234 56.6862H82.1309C82.1309 56.6862 77.0205 82.2313 51.4772 82.2313Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Core;
